import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Paisaje - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Paisaje"
        canonical="https://eduardoceballos.com/poemas/paisaje"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/paisaje",
          title: "Paisaje - Poemas",
          description: "Paisaje",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Paisaje</h1>
      <center>
        <p>
          De pronto el milagro de un desierto,
          <br />
          arena que baila con el viento.
          <br />
          las dunas donde el amor
          <br />
          juega su sueño erótico,
          <br />
          muestran su cintura,
          <br />
          la luna con Calchaquí ternura
          <br />
          sirve de testigo a ese idilio.
          <br />
          Toneles y álamos reciben al viajero
          <br />
          en el oasis del sol, del vino y los amigos.
          <br />
          Desde la parra crece memoria del tiempo
          <br />
          que el vino vuelve a dibujar en las cajas
          <br />
          un carnaval de antaño con albahaca.
          <br />
          Los cafayanes vivían con su pachamama
          <br />
          escondidos en su paisaje.
          <br />
          Hoy, la suma de las razas
          <br />
          con su sangre natural que canta.
          <br />
          Las comadres han poblado de telares el terruño,
          <br />
          el sol vive y reina entre las parras,
          <br />
          calchaquí es el acento del ají.
          <br />
          En la cocina vive la sabiduría ancestral
          <br />
          de ponerle gusto a las comidas.
          <br />
          En Cafayate sucede la alegría serenatera
          <br />Y el perfume de una rosa niña,
          <br />
          esperanza que alegre sube
          <br />y vive con el ritmo antiguo de una zamba.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
